import React, { useState } from 'react';
import { LiteAccessComponent } from './liteAccess';


export function HeroLiteComponent() {
    return (<section id="lite-section" className="hero" style={{ marginTop: 0 }} >

        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-7 col-xl-6 d-flex flex-column">
                    <div className="hero__content hero__content--first ">
                        <h1 className="hero__title"><strong>PROFITI</strong> <br />отримай <strong>LITE</strong> версію безкоштовно.</h1>
                    </div>
                    <br />
                    <div className="hero__content hero__content--second " style={{marginBottom: '20px'}}>
                        <button className="form__btn" type="button" data-bs-target="#modal-lite" data-bs-toggle="modal">Отримати доступ</button>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-2">
                    <div className="hero__content hero__content--second">
                        <div className="node node--hero">
                            <h3 className="node__title node__title--green"><b>PROFITI</b> lite</h3>
                            <span className="node__date">3 дні</span>
                            <span className="node__price"><b>Безкоштовно</b></span>
                            <span className="node__line"><img src="/img/dodgers/dots--line-green.svg" alt="" /></span>
                            <ul className="node__list">
                                <li><i className="ti ti-circle-check"></i><b>Автоматичне створення угод</b> </li>
                                <li><i className="ti ti-circle-check"></i><b>Зміна мультиплікатора</b><br />
                                    <ul className='subList'>
                                        <li>по котируванню</li>
                                        <li>по інвестованій сумі</li>
                                        <li>по сумі просадки</li>
                                    </ul> </li>
                            </ul>
                            <ul className="node__list notAvailable">
                                <li><i className="ti ti-circle-x"></i><b>Обмеження кількості створення угод</b> </li>
                                <li><i className="ti ti-circle-x"></i><b>Автоматичне збільшення суми угоди</b> </li>
                                <li><i className="ti ti-circle-x"></i><b>Автоматичне переведення в беззбиткову зону</b> </li>
                                <li><i className="ti ti-circle-x"></i><b>Налаштування умов для зміни мультиплікатора</b> </li>
                            </ul>
                            <span className="stats__dodger stats__dodger--left stats__dodger--green"></span>
                            <span className="stats__dodger stats__dodger--right stats__dodger--green"></span>
                            <span className="screw screw--br"></span>
                            <span className="screw screw--bl"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal modal--auto fade" id="modal-lite" tabIndex="-1" aria-labelledby="modal-apool" aria-hidden="true">
            <LiteAccessComponent />
        </div>
    </section>)
}