import React from "react";

export function InfoComponent() {
    return (<div >
        <div className="section section--head info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="section__title info">
                            <h1>Інструкція <strong>PROFITI</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section info">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1">
                        <div className="article">
                            <div className="article__content">
                                <div className="article__meta">
                                    <span className="article__date"><i className="ti ti-calendar-up"></i> 27.01.24</span>
                                </div>
                                <h2>Встановлення</h2>
                                <p>Завантажити <strong>PROFITI</strong> можна <a className="custom-link" href="https://chrome.google.com/webstore/detail/profiti/fmgdeoheeeeoogjeigbhlonieccbdngh?hl=uk&authuser=0" target="_blank" rel="noopener noreferrer" > тут</a>.  Для коректної роботи розширення використовуємо лише <span className="bold-text">Google Chrome</span>.</p>

                                <p>Натискаємо на кнопку <span className="bold-text">Додати в Chrome</span></p>
                                <img src="/img/info-0.1.X/add-to-chrome.png" alt="" />
                                <p> У вікні натискаємо <span className="bold-text">Додати розширення</span>. </p>
                                <img src="/img/info-0.1.X/add-extension.png" alt="" />
                                <p><strong>PROFITI</strong> запускатиметься автоматично.</p>
                                <h3>Що варто памʼятати?</h3>
                                <blockquote>Якщо ви увімкнете автозбільшення в Libertex та Profiti, вони працюватимуть паралельно.</blockquote>
                                <blockquote>Важливо мати на увазі, що прибуток в розширенні починає підтягуватись лише після першого оновлення прибутку на сайті. Тобто, ви запустили бота (натиснули кнопку "старт"), вибрали існуючу угоду, але прибуток буде "0" до оновлення прибутку на сайті. </blockquote>
                                <blockquote>Якщо ви перемикаєтесь між різними угодами, варто звертати увагу на налаштування, і в першу чергу мультиплікатора, тому що деякі інструменти мають різні мінімальні та максимальні значення (EURUSD - min: 1, max: 999. BTCUSD - min: 5, max: 500)</blockquote>
                                <blockquote>Памʼятайте, що на демо і на реальному рахунках різний стоп-аут (демо - 100%, реал - 90%).</blockquote>
                                <blockquote><strong>Profiti</strong>&nbsp; - це застосунок, котрий має полегшити торгівлю. На даний момент він не може повноцінно працювати самостійно. Тому я не рекомендую залишати вашу угоду без нагляду.</blockquote>
                                <blockquote>Якщо ви користуєтесь <span className="bold-text">зміною мультиплікатора по котируванню</span> або <span className="bold-text">умовами з типом котирування,</span>&nbsp; звертайте увагу на графік, по якому дивитись котирування. Для угоди в ріст вам треба ціна bid, для угоди в падіння треба ask.</blockquote>
                                <blockquote>Будьте уважні. При запуску бота функція <span className="bold-text">зміни мультиплікатора</span>&nbsp; вмикається автоматично. Допустимо ви вчора торгували на парі BTCUSD і виставили <span className="bold-text">зміну мільтиплікатора по котируванню.</span>&nbsp; Сьогодні ви вирішили торгувати по EURUSD... Котирування зовсім інші. Мультиплікатор угоди може відразу змінитись на той, який ви вказали в налаштуваннях.</blockquote>
                                <blockquote>Також, через особливості алгоритму обчислення, іноді є розходження в прибутку, котрий виводиться в розширенні та на самому сайті. Це можу бути помітно на великих сумах (наприклад 10000$ - 15000$ з великим плечем). </blockquote>
                                <blockquote>Будь ласка, прочитайте повністю інструкцію та навчіться користуватись на демо-рахунку. В нас є ще багато ідей для того, щоб зробити торгівлю більш простішою, безпечною та прибутковою. Цей документ я буду оновлювати у міру вдосконалення нашого додатку. Також рекомендую доєднатися до нашого <a href='https://t.me/+Wo8C2i93Kp9mMzZi' className="custom-link" target="_blank" rel="noopener noreferrer" >телеграм-каналу.</a></blockquote>
                                <blockquote><span className="bold-text">Стосується зміни мультиплікатора</span>. Не для всіх інструментів максимальний і мінімальний мультиплікатор є однаковим. Наприклад, максимальний для EUR/USD - 999, Bitcoin - 300. Мінімальний для EUR/USD - 1, Bitcoin - 5, тобто постійно потрібно було слідкувати за цим. На даний момент, ми зробили так, щоб коли ви випадково забули змінити налаштування, то мульт буде змінено на найближчий допустимий мінімальний чи максимальний. Давайте розглянемо на прикладі. В мене в налаштуваннях вказано змінювати мультиплікатор при просадці в 100$ на 1. Але поточна угода по Bitcoin. Мультиплікатор, який встановлено у налаштуваннях - не підходить і раніше раніше викликало би постійну генерацію помилки. Зараз мульт зміниться на мінімально допустимий (5).</blockquote>
                                <blockquote> <span className="bold-text">Розширення може працювати некоректно після переходу компʼютера в сплячий режим або зникнення інтернету. Після таких випадків, обовʼязково перезавантажте сторінку. </span></blockquote>

                                <h2>Версія 0.1.10</h2>
                                <p>Привіт, друзі!</p>
                                <p>Нарешті ми закінчили нову версію і хочемо описати нововведення та зміни.</p>
                                <h3>Новини</h3>
                                <p>В додатку зʼявилась нова вкладка <span className="bold-text">Новини</span>&nbsp; поруч з <span className="bold-text">Економічним календарем</span>. </p>
                                <p>Давайте розберемось в цьому функціоналі.</p>
                                <p>Щоб відкрити: натисніть кнопку <span className="bold-text">Новини</span>&nbsp; -> перейдіть до вкладки <span className="bold-text">Новини</span>.</p>
                                <img src="/img/info-0.1.10/news-view.gif" alt="" />
                                <p>Новини у списку згруповані по часу виходу. Для кожної групи новин є можливість налаштувати сповіщення (за 60хв, 45хв, 30хв, 15хв, 5хв, 1хв) та зміну мультиплікатора до і після виходу.</p>
                                <p>Отже давайте розглянемо, як це працює.</p>
                                <img src="/img/info-0.1.10/news-claster-view.png" alt="" />
                                <p>Біля кожної групи новин є дата та час виходу (зелений прямокутник).</p>
                                <p>Помаранчевий прямокутник виділяє назви новин.</p>
                                <p>Правіше кожної групи новин є іконка шестерні (жовтий прямокутник). При натисненні на неї відкривається меню редагування.</p>
                                <img src="/img/info-0.1.10/edit-cluster-news.png" alt="" />
                                <p>Зеленим кольором показано секцію для редагування сповіщень по новинам. </p>
                                <p>Просто ставиться галочка за той час, за який ви хочете отримати сповіщення про вихід новин.</p>
                                <p>Розглянемо поля в помаранчевому прямокутнику. За допомогою них налаштовується зміна мультиплікатора по новинах. </p>
                                <p><span className="bold-text">До(хв)</span>&nbsp; - час перед новиною, за який буде змінено мультиплікатор. (від 5 до 60)</p>
                                <p><span className="bold-text">Після(хв)</span>&nbsp; - час після новини, коли буде змінено мультиплікатор. (від 5 до 60)</p>
                                <p><span className="bold-text">Мульт</span>&nbsp; - Значення мультиплікатора, на який він зміниться перед новиною, як визначено у полі <span className="bold-text">«До(хв)»</span>&nbsp; (від 1 до 999)</p>
                                <p><span className="bold-text">Мульт(після)</span>&nbsp; - мультиплікатор, котрий буде встановлено після новини. (від 1 до 999), як визначено у полі <span className="bold-text">«Після(хв)»</span>.</p>
                                <blockquote><span className="bold-text">Важливо</span>. Встановлення цього мультиплікатора має найнижчий приорітет, тобто, мультиплікатор зміниться лише у випадку, якщо нема підходящої умови або налаштувань зміни мультиплікатора.</blockquote>
                                <p>Жовтим кольором виділено кнопки <span className="bold-text">«Очистити»</span>&nbsp; та <span className="bold-text">«Зберегти»</span>&nbsp;</p>
                                <p><span className="bold-text">Очистити</span>&nbsp; - знімає всі галочки і очищає всі поля.</p>
                                <p><span className="bold-text">Зберегти</span>&nbsp; - зберігає всі внесені зміни.</p>
                                <img src="/img/info-0.1.10/configured-claster.png" alt="" />
                                <blockquote><span className="bold-text">Важливо</span>. Мультиплікатор змінюється в конкретний час. Якщо глянути на картинку, що зверху, то в 01.15 мультиплікатор зміниться на 1, а в 01.50 на 300. В проміжку з 01.15 до 01.50 мультиплікатор змінюватись не буде.</blockquote>
                                <p>Після налаштувань новина буде мати жовтуватий відтінок. Зеленим кольором виділені проміжки часу, за які буде показуватись сповіщення. Після цього опис налаштування зміни мультиплікатора.</p>
                                <p>Група новин, котра активна зараз, має червоний відтінок.</p>
                                <img src="/img/info-0.1.10/active-news-item.png" alt="" />
                                <p>Щоб увімкнути роботу з новинами, потрібно натиснути на цю кнопку (зелений колір - вкл, червоний - вимкн.)</p>
                                <img src="/img/info-0.1.10/enable-news.png" alt="" />
                                <p>Також є можливість оновити список новин:</p>
                                <img src="/img/info-0.1.10/reload-news.png" alt="" />
                                <p>В секції налаштувань створення угод, додано галочку <span className="bold-text">«Блокувати створення під час новин»</span>, яка по замовчуванню включена. Вона блокує автоматичне створення нових угод під час дії активної групи новин.</p>
                                <img src="/img/info-0.1.10/block-by-news.png" alt="" />
                                <p><span className="bold-text">Є деякі нюанси:</span></p>
                                <blockquote>Новини по часу можуть накладатись одна на одну. В такому випадку перевага надається тій, що настане раніше.</blockquote>
                                <blockquote>Новини у заданому інтервалі часу (від <span className="bold-text">«До(хв)»</span>&nbsp; до <span className="bold-text">«Після(хв)»</span>) мають найвищий пріоритет. Тобто якщо є активна новина, то зміна мультиплікатора згідно умов у налаштуваннях додатка ігнорується. По закінченню інтервалу дії активних новин в пріоритеті налаштування розділу «Зміна мультиплікатора».</blockquote>
                                <h3>Інструмент</h3>
                                <p><span className="bold-text">Інструмент</span>&nbsp;-&nbsp;поле у секції налаштувань створення угод. В полі є весь список інструментів доступних на платформі. В ньому можна здійснювати пошук та додавати інструменти в обрані (лише для плагіну).</p>
                                <img src="/img/info-0.1.10/instrument.gif" alt="" />
                                <h3>Утиліти</h3>
                                <p>Це нова секція, де будуть додаватись інструменти, котрі полегшують та покращують торгівлю. У версії 0.1.10 вже функціонує <span className="bold-text">калькулятор</span>.</p>
                                <p><span className="bold-text">Утиліти</span>&nbsp;-&nbsp;це секція, що зʼявляється згорнутою, щоб її розгорнути, потрібно натиснути на іконку.</p>
                                <img src="/img/info-0.1.10/tools-show.png" alt="" />
                                <p><span className="bold-text">Калькулятор</span>&nbsp;-&nbsp;робить розрахунок для активної угоди. Також ви зможете розрахувати значення по угоді при зміні мультиплікатора (профіт, комісія, ціна відкриття, ціна піпсу), дані обраховуються в режимі реального часу по актуальних даних активної угоди.</p>
                                <img src="/img/info-0.1.10/calc-filled.png" alt="" />
                                <p>Працювати з калькулятором просто, все що потрібно - ввести значення у відповідні поля і калькулятор розрахує значення по угоді з новим мультиплікатором. </p>
                                <h3>Зміни в інтерфейсі</h3>
                                <p>Ми перемістили вкладки <span className="bold-text">«Повідомлення»</span>&nbsp;та <span className="bold-text">«Статистика»</span>&nbsp;в нову секцію <span className="bold-text">«Інформація»</span>, котра знаходиться в лівий нижній частині екрану. Давайте глянемо як вона виглядає. Назву <span className="bold-text">«Статистика»</span>&nbsp;змінено на <span className="bold-text">«Закриті угоди»</span>, що відповідає змісту.</p>
                                <img src="/img/info-0.1.10/info-section.png" alt="" />
                                <p>Секція зʼявляється в розгорнутому вигляді, але її можна згорнути (в такому разі повідомлення будуть дублюватися над згорнутою секцією), натиснувши на іконку.</p>
                                <img src="/img/info-0.1.10/info-section-hide.png" alt="" />
                                <p>Були змінені повідомлення. Вони мають різні типи та різний зовнішній вигляд (колір) для кожного  із типів.</p>
                                <img src="/img/info-0.1.10/messages.png" alt="" />
                                <p>Зазнало змін і вікно налаштувань. Змінились колір, валідація, а також збереження даних. Розглянемо детальніше, що мається на увазі.</p>
                                <p>Раніше кожна зміна налаштувань вимагала зупинки додатку. Зараз все відбувається трошки по іншому. Зупиняти роботу не потрібно. Зміни в будь-якому полі підсвічуються зеленим (коректні дані) або червоним (некоректні дані). Для застосування змін в налаштуваннях під кожною секцією є кнопка <span className="bold-text">«Зберегти»</span>. Якщо зміни коректні, вона стає активною (зеленою). Після збереження додаток використовуватиме нові налаштування.</p>
                                <img src="/img/info-0.1.10/config.gif" alt="" />

                                <h2>Версія 0.1.0</h2>
                                <p>Всім привіт. В даному документі хочу коротко описати (наскільки то можливо) весь функціонал автоматизації від <strong>PROFITI</strong>. Почнемо з налаштувань.</p>
                                <h3>Створення нових угод</h3>
                                <img src="/img/info-0.1.X/create-deal.png" alt="" />

                                <p>Дана секція відповідає за налаштування для автоматичного, а також для напівавтоматичного створення угод.</p>
                                <p><span className="bold-text">-А- Інвестована сума.</span> Визначає суму, на яку будуть створюватись нові угоди. Тобто, це те саме значення, що ви вводите в поле "Сума угоди" у вікні створення нової угоди (ліва частина малюнку). Інвестована сума обмежена кількістю доступних грошей на вашому рахунку та мінімальним обсягом торгів для вибраного інструменту (EURUSD - 10$, BTCUSD - 20$).</p>
                                <p><span className="bold-text">-В- Мультиплікатор.</span> Значення, введене в поле, буде використано при створенні нових угод для мультиплікатора (плеча). Тобто, це те саме значення, що ви вводите в поле "Коефіцієнт" у вікні створення нової угоди (ліва частина малюнку). </p>
                                <p>Значення мультиплікатора не може бути менше нуля та більше за 999. Додатково деякі інструменти обмежують мінімальну його межу. (Наприклад максимальний мультиплікатор для EURUSD - 999, BTCUSD - 300)</p>
                                <p><span className="bold-text">-С- Максимальний Take Profit.</span> Дане поле є необовʼязковим для створення угоди. При відсутності галочки воно буде ігноруватись. Якщо це поле буде увімкнене, нова угода буде створена з Take Profit (ліва частина малюнку).   </p>
                                <p><span className="bold-text">-D- Операція.</span> Це випадаючий список, де можна вибрати лише два значення (buy/sell). Угоди будуть створюватись в тому напрямі, який буде вибраний.</p>
                                <p><span className="bold-text">-E- Інструмент.</span> Вибирається інструмент, по якому будуть створюватись нові угоди.</p>
                                <img src="/img/info-0.1.X/instrument.png" alt="" />
                                <p>Коли відкриєте даний випадаючий список, ви побачите дві категорії:</p>
                                <p><span className="bold-text">Обране</span> (інструменти, позначені вами як обрані) та <span className="bold-text">Інші</span> (всі інші інструменти, котрі є на платформі).</p>
                                <p><span className="bold-text">-F- Автозбільшення (Libertex).</span> Якщо виставити дану галочку, нові угоди будуть створюватись з увімкненим автозбільшенням (права частина першого малюнку).</p>
                                <p><span className="bold-text">-G- Обмежити кількість угод.</span> Завдяки цій опції ви можете обмежити кількість автоматично створених угод. Тобто, якщо ви поставите 5 угод та запустите PROFITI, створиться перша угода. Після її закриття - друга... Після 5-тої, створення нових угод припиниться.</p>

                                <h3>Автозбільшення (PROFITI)</h3>
                                <p>Даний функціонал розроблений як альтернатива для автозбільшення (Libertex). Тут є свої плюси та мінуси.</p>
                                <p><span className="bold-text">Плюси:</span></p>
                                <ul>
                                    <li>Ви можете налаштовувати при яких умовах та на скільки буде збільшено вашу інвестовану суму.</li>
                                    <li>Автозбільшення від <string>PROFITI</string> не настільки сильно вганяє в просадку. Як ви маєте знати, стандартне доповнення відбувається при 50% просадки на 50% від інвестованої суми. При високій волатильності буває швидке накручування інвестованої суми. За лічені секунди можна влити велику частину депозиту в угоду.</li>
                                </ul>
                                <p><span className="bold-text">Мінус (він один):</span></p>
                                <ul>
                                    <li>На відміну від стандартного автозбільшення, <strong>PROFITI</strong> не може гарантувати на 100%, що Вас не вибʼє з угоди. </li>
                                </ul>

                                <p>Що це означає? Давайте глянемо дане відео...</p>
                                <img src="/img/info-0.1.X/video-break-autoincrease.gif" alt="" />
                                <p>Що ми тут бачимо? В нас відкрита угода по <span className="bold-text">USDJPY</span>, рух ринку не в нашому напрямі (це нормально, недобре, але буває). В якийсь момент великий стрімкий рух не туди... І нас вибиває з угоди. Чому? На жаль збільшення суми угоди відбувається не миттєво і тому є такий ризик (з підвищенням відсотку просадки, цей ризик збільшується) викидання з угоди про який я маю попередити. </p>
                                <p>А тепер до налаштувань:</p>
                                <img src="/img/info-0.1.X/autoincrease.png" alt="" />
                                <p><span className="bold-text">-А- Відсоток.</span> Це відсоток просадки. Тобто при досягненні 70% просадки (зі 100$ то буде 70$), збільшить суму вашої угоди на заданий відсоток або величину мінімального обсягу торгів для вибраного інструмента. В дане поле можна ввести значення від 30 до 70. <span className="bold-text">Будьте уважні!!! На даний час на демо-рахунку стоп аут - 100%, а на реальному 90%</span></p>
                                <p><span className="bold-text">-В- % збільшення.</span> Це сума, на яку буде збільшено суму угоди при досягненні визначеного відсотку просадки.</p>
                                <p>Ви вказуєте відсоток, наприклад 10. Якщо брати приклад з малюнку і вважати, що на даний момент в угоді 100$, то при просадці в 70$ у вашу угоду буде додано 10$ (10% від 100$ буде 10$). Значення введене в даному полі може бути від 10 до 100.</p>
                                <p><span className="bold-text">Є ще нюанс. Кожен інструмент має мінімальну суму доповнення. Наприклад EURUSD - 10 $, BTCUSD - 20$. Що буде, якщо цих 10% від суми інвестування менше мінімальної суми доповнення?</span></p>
                                <p><span className="bold-text">Правильно. Буде доповнено на мінімальну суму, допоки цих 10% не будуть більше, ніж мінімальна сума.</span></p>
                                <h3>Зміна мультиплікатора</h3>
                                <p>Це, мабуть, найбільша секція. Тому будемо розглядати її у два етапи.</p>
                                <img src="/img/info-0.1.X/change-mult.png" alt="" />
                                <p><span className="bold-text">-А- Максимальна інвестована сума.</span>Коли ваша інвестована сума в активній угоді буде рівна або більше значення цього поля, мультиплікатор буде змінено на той, що міститься в полі "мультиплікатор".</p>
                                <p><span className="bold-text">-B- Максимальна сума просадки.</span>Коли ваш прибуток буде менший або рівний значенню в полі, мультиплікатор буде змінено на той, що вказано в полі "мультиплікатор". Майте на увазі, ви вводите в це поле додатне значення. Тобто введене значення "200" буде означати просадку "-200".</p>
                                <p><span className="bold-text">-С- Котирування. </span>Щоб пояснити даний функціонал, уявімо таку ситуацію. У нас є угода на купівлю по BTCUSD і ми ввели значення 30123.57 (лінія на малюнку).</p>
                                <img src="/img/info-0.1.X/quote-buy.png" alt="" />
                                <p>Коли мультиплікатор зміниться по котируванню? Мультиплікатор буде змінено в той момент, коли наше поточне котирування активної угоди буде менше або рівне тому, яке ми ввели в поле. Отже, коли котирування активної угоди буде 30123.57 або менше, мультиплікатор зміниться.</p>
                                <p>Тепер давайте уявимо, що наша угода на продаж.</p>
                                <img src="/img/info-0.1.X/quote-sell.png" alt="" />
                                <p>В даному випадку все навпаки. Мультиплікатор буде змінено, коли котирування вибраної угоди буде таке саме як ми ввели в поле "котирування" або більше. Тобто в даному прикладі 30167.79 або більше.</p>
                                <p>Цим інструментом варто навчитись користуватись на демо-рахунку. Щоб уникнути неприємностей на реальному.</p>
                                <p><span className="bold-text">-D- Мультиплікатор.</span>Саме на це значення буде змінено мультиплікатор активної угоди, коли буде задовольнятися вибрана умова. </p>
                                <p><span className="bold-text">-E- Кнопки зміни мультиплікатора.</span>Ці кнопки були додані для зручності. При натисненні на кнопку значення поля <span className="bold-text">Мультиплікатор (-D-)</span> буде змінено на те, що на кнопці.</p>
                                <p>Перейдемо до другої частини цієї секції.</p>
                                <p>Що таке умови?</p>
                                <p>Це інструмент, котрий дозволяє вам задати умову та мультиплікатор, який ставиться, коли вона задовольняється.</p>
                                <p>Щоб створити умову, потрібно натиснути кнопку "Додати умову", у вас з’явиться форма для її створення. </p>
                                <img src="/img/info-0.1.X/condition-add.png" alt="" />
                                <p><span className="bold-text">-A- Тип.</span> Випадаючий список для вибору типу умови. В нас їх є три.</p>
                                <ul>
                                    <li>Інвестована сума</li>
                                    <li>Сума прибутку</li>
                                    <li>Котирування</li>
                                </ul>
                                <p>Доки у вас не вибраний один із типів, всі інші поля будуть заблоковані.</p>
                                <p><span className="bold-text">-В- Від.</span>Це значення, від якого починає діяти умова (нижня межа).</p>
                                <p><span className="bold-text">-С- До.</span>Значення, до якого буде діяти умова (верхня межа).</p>
                                <p><span className="bold-text">-D- Мульт.</span>Мультиплікатор, який буде встановлено, якщо умова задовольняється.</p>
                                <p><span className="bold-text">-Е- Відміна.</span></p>
                                <p><span className="bold-text">-F- Збереження.</span></p>
                                <p>Над кнопкою "Додати умову" відображається список створених умов.</p>
                                <img src="/img/info-0.1.X/exist-condition.png" alt="" />
                                <p><span className="bold-text">-A- Увімкнення/вимкнення умови.</span></p>
                                <p><span className="bold-text">-B- Опис умови.</span></p>
                                <p><span className="bold-text">-C- Виклик форми редагування.</span>Форма редагування виглядає так само, як і створення умови. </p>
                                <img src="/img/info-0.1.X/edit-condition.png" alt="" />
                                <p><span className="bold-text">-D- Видалення.</span></p>
                                <p>Ну і після опису всіх налаштувань давайте розглянемо, як це працює.</p>
                                <p>Отже, давайте розглянемо умови кожного типу.</p>
                                <ul>
                                    <li><span className="bold-text">Тип: Сума прибутку</span></li>
                                    <li><span className="bold-text">Від: -10</span></li>
                                    <li><span className="bold-text">До: 10</span></li>
                                    <li><span className="bold-text">Мульт: 25</span></li>
                                </ul>
                                <p>Приведена умова означає:</p>
                                <p><span className="bold-text">Якщо прибуток угоди попадає в інтервал від -10$ до 10$ включно, мультиплікатор буде змінений на 25.</span></p>
                                <p>Наступний приклад:</p>
                                <ul>
                                    <li><span className="bold-text">Тип: Інвестована сума</span></li>
                                    <li><span className="bold-text">Від: 100</span></li>
                                    <li><span className="bold-text">До: 500</span></li>
                                    <li><span className="bold-text">Мульт: 50</span></li>
                                </ul>
                                <p>По аналогії з попереднім прикладом, читається вона так:</p>
                                <p><span className="bold-text">Якщо інвестована сума угоди знаходиться в проміжку від 100$  до 500$, включаючи обидва значення, мультиплікатор буде змінений на 50.</span></p>
                                <p>Ну й тепер розглянемо останній тип:</p>
                                <ul>
                                    <li><span className="bold-text">Тип: Котирування</span></li>
                                    <li><span className="bold-text">Від: 1.09956</span></li>
                                    <li><span className="bold-text">До: 1.09999</span></li>
                                    <li><span className="bold-text">Мульт: 50</span></li>
                                </ul>
                                <p>Щоб було легше зрозуміти, давайте глянемо на малюнок:</p>
                                <img src="/img/info-0.1.X/qutoe-condition.png" alt="" />
                                <p>Я вирішив трошки візуалізувати, щоб було легше зрозуміти.</p>
                                <p>Отже, нижня лінія це значення "Від", верхня - "До". Зеленим кольором зафарбована область, в якій буде змінено мультиплікатор.</p>
                                <p>Якщо котирування нашої угоди буде в цьому проміжку, мультиплікатор зміниться на 50.</p>
                                <p>То для чого потрібні ці умови?</p>
                                <p>Умови дозволяють задати поведінку активній угоді в залежності від ваших налаштувань, робить трошки гнучкішою за рахунок зміни мультиплікатора.</p>
                                <p>Ще деякі нюанси, які потрібно знати і враховувати:</p>
                                <blockquote>Значення "Від" має бути меншим за значення "До".</blockquote>
                                <blockquote>Не можна одночасно вмикати умови та стандартні налаштування зміни мультиплікатора. </blockquote>
                                <blockquote>Не можна вмикати умови різних типів одночасно.</blockquote>
                                <blockquote>Якщо одна умова накладається на іншу, ви не зможете їх увімкнути.</blockquote>
                                <blockquote>Якщо ви створили умову з мультиплікатором, який є недоступним на даному інструменті (наприклад 999 мульт на інструменті BTCUSD, де максимальний мульт 300), умова вимкнеться під час роботи при спробі змінити мультиплікатор.</blockquote>
                                <h3>Беззбитковість</h3>
                                <p>За допомогою даного функціоналу ви можете налаштувати автоматичне переведення вашої угоди в беззбиткову зону.</p>
                                <img src="/img/info-0.1.X/break-even.png" alt="" />
                                <p><span className="bold-text">-А- Мінімальний Take Profit.</span>&nbsp;В даному полі ви налаштовуєте мінімальний Take Profit. </p>
                                <p><span className="bold-text">-В- Відступ Take Profit (PIPS).</span>&nbsp;Відступ у PIPS. Мінімальне значення 5 (обмеження системи).</p>
                                <p>Коли почне спрацьовувати беззбитковість?</p>
                                <p>Беззбитковість спрацьовує, коли задовольняється умова:</p>
                                <blockquote>Прибуток на рівні заданого відступу (поточне котирування - відступ Take Profit (PIPS)) > Мінімальний Take Profit</blockquote>

                                <p>Якщо  після першого спрацювання угода і далі йде в нашу сторону, беззбиткова зона завжди буде оновлюватись, і таким чином ми себе вбезпечуємо.</p>
                                <h3>Давайте тепер розглянемо інтерфейс.</h3>
                                <img src="/img/info-0.1.X/interface.png" alt="" />
                                <p>Я поділив інтерфейс на 5 частин. Розглянемо кожну з них.</p>
                                <p><span className="bold-text">-А- Секція управління інтерфейсом.</span>&nbsp;В правій частині є три кнопочки. Давайте розберемось, що вони роблять.</p>

                                <p>Перша кнопочка згортає бот.  Інтерфейс в стандартному своєму вигляді займає багато місця і тому було вирішено додати можливість зробити вигляд боту меншим. </p>
                                <img src="/img/info-0.1.X/min-ui.png" alt="" />
                                <p>Тут відображається загальна інформація про активну угоду (інструмент, напрям та поточний прибуток)</p>
                                <p>Також є дві кнопки. Та, що в правій частині, при наведенні відкриває меню фактично продубльоване з секції <span className="bold-text">-В-</span>.</p>
                                <img src="/img/info-0.1.X/min-ui-menu.png" alt="" />
                                <p>Невеличка кнопка по центру при натисненні повертає до стандартного вигляду бота.</p>
                                <img src="/img/info-0.1.X/min-ui-video.gif" alt="" />
                                <p>Наступна кнопка навпаки розгортає вікно боту (подвійна стрілка вгору). Давайте глянемо, що буде після натиснення.</p>
                                <img src="/img/info-0.1.X/max-ui.png" alt="" />
                                <p>Вікно боту збільшилося і ми бачимо дві вкладки: <span className="bold-text"><i>Повідомлення</i></span> та <span className="bold-text"><i>Статистика</i></span>. <span className="bold-text"><i>Повідомлення</i></span> - показуються всі дії, котрі робить бот (збільшення суми угоди, зміна мультиплікатора, створення угод, встановлення беззбиткової зони), також тут показуються й помилки, які відображатимуться червоним кольором. <span className="bold-text"><i>Статистика</i></span> - відображаються результати вашої торгівлі. Можна вибрати три періоди:</p>
                                
                                <p><span className="bold-text">Сьогодні</span>&nbsp; - всі закриті угоди за поточну добу.</p>
                                <p><span className="bold-text">Поточний тиждень</span>&nbsp; - закриті угоди за поточний тиждень з понеділка по неділю.</p>
                                <p><span className="bold-text">Поточний місяць</span>&nbsp; - закриті угоди за поточний місяць. Від першого дня до останнього дня місяці.</p>
                                <p>Знизу таблиці є стрілочки для гортання сторінок, а також сумарний прибуток за вибраний період. Давайте глянемо, як воно працює:</p>
                                <img src="/img/info-0.1.X/statistcs-video.gif" alt="" />
                                <p>Ну і остання кнопка (хрестик) згортає бот до стандартного вигляду.</p>
                                <p><span className="bold-text">-В- Секція управління функціоналом.</span>&nbsp;Якщо простіше, тут ви можете вмикати або вимикати функціональність бота. Також ця панель дублюється і в мінімізованому вигляді.</p>
                                <p>Всі кнопки мають три варіанти забарвлення:</p>
                                <p><span className="bold-text">Зелений</span>&nbsp; - функціональність ввімкнена.</p>
                                <p><span className="bold-text">Червоний</span>&nbsp; - вимкнено.</p>
                                <p><span className="bold-text">Сірий</span>&nbsp; - заблокована, є якісь помилки в налаштуваннях.</p>
                                <img src="/img/info-0.1.X/func-button-video.gif" alt="" />
                                <p><span className="bold-text">-С- Вибір активної угоди. </span></p>
                                <img src="/img/info-0.1.X/active-position.png" alt="" />
                                <p>У випадаючому списку ви побачите всі відриті угоди, а також "None". З активними угодами все просто, вибрали угоду і бот її супроводжує.</p>
                                <p>Якщо ж ви вибираєте "None", це означає, що жодної угоди у вас не вибрано.</p>
                                <p>Тут треба бути уважними. Якщо у вас ввімкнено бот і, одночасно,  автоматичне створення угод, то відразу після вибору "None", створиться нова угода.</p>
                                <p>Якщо ж автоматичне створення угод (перша кнопка з попередньої секції) вимкнено, зʼявиться додаткова панель для створення угоди.</p>
                                <img src="/img/info-0.1.X/create-one-deal.png" alt="" />   
                                <p>На цій панелі є три кнопки, всі вони створюють угоди за налаштуваннями "Створення нових угод":</p>   
                                <p>↘ - створення угоди в продаж.</p>                                                
                                <p>↗ - створення угоди в купівлю.</p>
                                <img src="/img/info-0.1.X/create-one-deal-video.gif" alt="" /> 
                                <p><span className="bold-text">--D- Індикатор прибутку.</span>&nbsp;Відображає поточний прибуток активної угоди.</p>
                                <img src="/img/info-0.1.X/price-indicator.png" alt="" /> 
                                <p><span className="bold-text">2.96</span>&nbsp;- поточний прибуток вибраної угоди.</p>
                                <p><span className="bold-text">-1.98</span>&nbsp;- це значення зʼявляється, коли активна угода прибуткова. Що воно означає? Це прибуток по беззбитковості. Тобто вирахуваний прибуток з врахуванням того відступу, який ми налаштовували (в моєму випадку 10 піпсів)</p>
                                <p>Стрілочка зліва зʼявляється лише тоді, коли у вас вибрана активна угода. Вона відкриває додаткове меню для швидкої взаємодії з активною угодою. Натискаємо: </p>
                                <img src="/img/info-0.1.X/additional-menu.png" alt="" /> 
                                <p>Тут ми можемо швидко збільшити суму угоди та змінити мультиплікатор. </p>
                                <p>Щоб збільшити суму угоди, вводимо число (памʼятайте про мінімальну суму збільшення для кожного інструмента) і натискаємо галочку навпроти поля.</p>
                                <p>Так само працює і зміна мультиплікатора.</p>
                                <p>Є ще кнопки, для швидкої зміни мультиплікатора (при натисненні мультиплікатор зміниться на той що вказаний на кнопці)</p>
                                <img src="/img/info-0.1.X/additional-menu-video.gif" alt="" /> 
                                <p><span className="bold-text">-Е- Панель управління ботом.</span></p>
                                <p>Ну і останнє)</p>
                                <ul>
                                    <li><span className="bold-text">налаштування</span>&nbsp; - відкриваються налаштування роботи бота. Якщо бот включений, налаштування доступні лише для перегляду.</li>
                                    <li><span className="bold-text">новини</span>&nbsp; - відкривається календар економічних новин.</li>
                                    <li><span className="bold-text">старт/стоп</span>&nbsp; - запуск/зупинка роботи бота.</li>
                                </ul>
                                <img src="/img/info-0.1.X/contol-buttons-video.gif" alt="" /> 
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}