import React from 'react';
import analytics from '../../mixpanel/analytics';

export function SubscriptionPurchaseComponent(){
    const trcWallet = 'TAVX4saVYR95xaJKWbwcTJKxUs4XHyQUFN';

    const copyToClipboard = async () => {
        analytics.track(`Скопійовано гаманець.`);
        await navigator.clipboard.writeText(trcWallet);
    };

    return(<div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
        <div className="modal__content">
            <button className="modal__close" type="button" data-bs-dismiss="modal" aria-label="Close"><i className="ti ti-x"></i></button>

            <h4 className="modal__title">Підписка</h4>

            <p className="modal__text">Оберіть свій пакет підписки: <br />
            <br /><strong>1 місяць:</strong>&nbsp;50$<br />
            <strong>6 місяців: </strong>&nbsp;200$<br />
            <strong>1 рік: </strong>&nbsp;300$<br /> <br />
            <strong>Для оплати:</strong><br />
            Будь ласка, здійсніть переказ на наш гаманець (USDT TRC20):<strong>&nbsp;{trcWallet}</strong><br /><br />
            <strong>Будьте обережні! Завжди перевіряйте адресу гаманця =)</strong>
            <form action="#" className="modal__form">
            <button className="form__btn" onClick={copyToClipboard} type="button">Копіювати гаманець</button><br />
            </form>
            <strong>Після оплати:</strong><br />
            
            Відправте фото квитанції та електронну адресу, на яку потрібно надати доступ, на нашу електронну скриньку: <strong>profiti.contact@gmail.com</strong> або в телеграм: <a className="custom-link" href='https://t.me/profiti_info'>тут</a><br /><br />
            <strong>Зверніть увагу:</strong> В даний час обробка платежів відбувається в ручному режимі, тому процедура може тривати до 24 годин. Як тільки доступ буде надано, ми негайно повідомимо вас на вказану електронну адресу.
            </p>

            <form action="#" className="modal__form">
                <button className="form__btn" type="button" data-bs-dismiss="modal">Зрозуміло</button>
            </form>

            <span className="screw screw--big-tl"></span>
            <span className="screw screw--big-bl"></span>
            <span className="screw screw--big-br"></span>
        </div>
    </div>
</div>);
}